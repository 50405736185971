:root {
  --white: #f0efe7;
  --light-white: #aaa;
  --black: #3d3d3d;
  --light-bg: #4c4c4c;
  --blue: #2d22ca;
}

body {
  background-image: linear-gradient(to top, #a8edea 0%, #fed6e3 100%);
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.text {
  color: white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.heading {
  text-align: center;
  margin-top: 0.3rem;
  margin-bottom: 1.2rem;
  font-size: 3rem;
  color: rgb(5, 5, 10);
  font-family: serif;
}

.heading span {
  color: blue;
}

/* Home College CSS */
.language {
  display: flex;
  margin-left: 10rem;
  align-items: center;
}

.college_info {
  text-align: center;
  margin-left: 1rem;
  flex-grow: 1; 
}

.college_head {
  margin-top: 0.5rem;
  margin-bottom: 1.2rem;
  font-size: 3rem;
  font-weight: bold;
  color: rgb(5, 5, 10);
  font-family: serif;
  cursor: default;
}

.language_buttons {
  display: flex;
  gap: 1rem; 
  align-items: flex-start; 
}

.language_buttons button {
  padding: 0.5rem;
  font-size: 1rem;
  background-color: #7d35bd;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease; 

  /* Hover effect */
  &:hover {
    background-color: #0056b3; 
  }
}

.college_head:hover {
  text-shadow: -3px 0 0 rgba(255, 0, 0, 0.7), 3px 0 0 rgba(0, 255, 255, 0.7);
}

/* Style the image */
.image img {
  width: 16rem;
  height: 15rem;
  margin-left: 80px;
  border-radius: 2rem;
  cursor: pointer;
  transition: transform 1000ms ease, border 0.3s ease;
  filter: drop-shadow(5px 5px 10px #000000);
}

.image img:hover {
  transform: scale(1.1);
  filter: brightness(1.2);
  filter: drop-shadow(5px 10px 15px #000000);
}

/* Style the content paragraph */

.content p {
  margin-left: 8rem;
  text-align:center;
  max-width: 80%;
  color: black;
  font-size: 22px;
}

.container {
  text-align: center;
  padding: 15px;
}

/* Style the button */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.btn {
  margin-top: 1rem;
  border-radius: 5rem;
  background-color: var(--light-bg);
  cursor: pointer;
  color: var(--white);
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.btn:hover {
  background-color: var(--white);
  color: var(--black);
}

/* 'About the Chairman's CSS */
.about {
  display: flex;
  text-align:right;
  font-size: 25px;
  font-family: monospace;
}

.language {
  flex: 1;
  text-align: left;
  padding-left: 20px;
  padding-right: 30px;
}

.slider-container {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  padding-left: 20px;
}

.user {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 15px;
}

.user img {
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  margin-right: 40px;
  box-shadow: 5px 5px 10px 1px #000000;
}

.user-info h3 {
  font-size: 3rem;
  font-family: "Times New Roman", Times, serif;
  text-align: center;
}

/* Footer CSS */
.footer {
  background-image: linear-gradient(to right, #243949 0%, #517fa4 100%);
  text-align: center;
}

.footer .share a {
  margin: 10px;
  height: 3rem;
  width: 3rem;
  line-height: 3rem;
  border-radius: 50%;
  font-size: 2rem;
  color: rgb(251, 251, 253);
}

.footer .share a:hover {
  text-shadow: 0 -1px 0 rgb(0, 8, 255), 0 3px 0 rgb(0, 8, 255), 0 5px 0 #60f,
    -2px -2px 15px rgb(0, 85, 255), 2px -2px 15px rgb(0, 94, 255),
    -2px 2px 15px rgb(0, 229, 255), 2px 2px 15px rgb(0, 255, 234);
}

.footer .credit {
  border-top: 0.1rem solid var(--light-white);
  padding-top: 8px;
  font-size: 30px;
  color: white;
}

.footer .credit span {
  color: black;
}
